import React from 'react'
import Particles from "react-particles";
import ParticleConfig from './config/ParticleConfig';
function ParticlesBackground() {
  return (
    
         <Particles params={ParticleConfig}></Particles>

  )
}

export default ParticlesBackground;